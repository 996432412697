@import 'src/assets/stylesheets/scss/tokens';
.loadingSpinnerContainer {
    position: absolute;
    width: 100%;
    height: 100%;
    background: $theme-background-primary;
    top: 7%;
    z-index: 2;
}

.horizontalScroll {
  :global {
    .rbc-time-view {
      border: 1px solid $theme-border-primary;
      border-radius: 0.25rem !important;
      box-shadow: none !important;
      display: grid;
      overflow-x: scroll;
      overscroll-behavior-x: none;
    }
  }
}

:global {
  #big-calendar-container-id {
    .rbc-time-view {
      border-radius: 5px;
      background: $theme-background-primary;
      box-shadow: 0px 0px 10px rgba(0,0,0,0.2);
      border: 1px solid $theme-border-primary;
      z-index: 1;
    }
  
    .rbc-header {
      border-bottom: unset;
    }
  
    .rbc-month-view {
      border-radius: 5px;
      background: $theme-background-primary;
      box-shadow: 0px 0px 10px rgba(0,0,0,0.2);
      border: none;
    }
  
    .rbc-today {
      background-color: $theme-background-secondary;
    }
  
  
    .rbc-addons-dnd.rbc-calendar {
      .rbc-toolbar .rbc-btn-group button {
        border-color: $theme-accent-primary;
        &.rbc-active {
          background-color: $theme-accent-primary;
          color: $theme-text-primary;
        }
      }
      .rbc-toolbar .rbc-btn-group:not(.last-btn-group) {
        button {
          background: $theme-background-primary;
          color: $theme-accent-primary;
          &.rbc-active {
            background-color: $theme-accent-primary;
            color: $theme-white;
          }
          &:hover {
            cursor: pointer;
          }
        }
        .rbc-toolbar-label {
          font-size: 1.3em;
          line-height: 1em;
          font-weight: 600;
          color: $theme-text-primary;
        }
      }
      .rbc-btn-group.last-btn-group {
        button {
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
          display: inline-flex;
        }
      }
    }
  
    .rbc-day-slot {
      .rbc-event.calendar-event.smallest-event-item.tiny, .rbc-event.calendar-availability.smallest-event-item {
        min-height: 16px;
      }
    }
    .is-loading {
      .rbc-btn-group {
        pointer-events: none;
        opacity: .5;
      }
    }
    .month-view {
      .rbc-event.calendar-event {
        padding: unset;
      }
      .rbc-event.calendar-event.block-off-time {
        .rbc-event-content {
          white-space: nowrap;
          overflow: hidden
        }
      }
    }
  }
  @media screen and (max-width: 778px) {
    .rbc-event.calendar-event {
      .rbc-addons-dnd-resizable {
        .rbc-event-content {
          &__title-wrapper:not([class*='blockContainerWrapper']) {
            svg {
              display: none;
            }
          }
        }
      }
    }
  }
  
  .rbc-header {
    font-size: 11px;
  }
  
  
  .rbc-event.calendar-event {
    padding: 3px 0 0 3px;
    transition: all 100ms ease-in-out;
  
    &:hover {
      z-index: 999;
      box-shadow: 0 5px 10px 0px rgba(0,0,0, 0.4) !important;
      border: 1px solid $theme-border-primary !important;
      margin-top: -3px;
    }
  
    .rbc-addons-dnd-resizable {
      display: flex;
      flex-direction: column;
      width: 100%;
      text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.5);
  
      .rbc-event-label {
        margin-bottom: 0px;
        opacity: 0.9;
        color: $theme-white;
        @include type-sm;
        letter-spacing: -0.04em;
        white-space: nowrap;
        text-overflow: unset;
      }
  
      .rbc-event-content {
        white-space: normal;
        word-break: break-all;
        overflow: unset;
        text-overflow: unset;
        height: unset;
        line-height: 7px;
        min-height: unset;
  
      }
    }
  }
  
  .rbc-event.calendar-event.with-label-spacing {
    .rbc-addons-dnd-resizable {
      .rbc-event-content {
        word-break: break-word;
      }
    }
  }
  
  .rbc-event.calendar-event.smallest-event-item {
    .rbc-addons-dnd-resizable {
      display: flex;
      flex-direction: column;
      .rbc-event-label {
        line-height: 9px;
      }
  
  
      .rbc-event-content {
        display: flex;
        align-items: flex-start;
        white-space: normal;
      }
    }
  }
  
  .rbc-event.calendar-event.smallest-event-item.tiny {
    padding: 0 0 0 1px;
    flex-flow: unset;
  
    .rbc-addons-dnd-resizable {
      flex-direction: row;
  
      .rbc-event-label {
        display: none;
      }
    }
  }
  
  .rbc-event.calendar-event.smallest-event-item.tiny.day-view {
    .rbc-event-label {
      display: block;
      margin-top: 1px;
    }
  }
  
  .rbc-event.calendar-event.block-off-time {
    opacity: 0.9;
  
    .rbc-addons-dnd-resizable {
      .rbc-event-content {
        margin-top: 1px;
        @include type-sm;
      }
    }
  }
  
  .rbc-event.calendar-event.hide-time-range {
    .rbc-addons-dnd-resizable {
      height: 100%;
      .rbc-event-label {
        display: none !important;
      }
    }
  }
  
  .rbc-event.calendar-event.hide-icon {
    .rbc-addons-dnd-resizable {
      .rbc-event-content {
        &__title-wrapper:not([class*='blockContainerWrapper']) {
          svg {
            display: none;
          }
        }
      }
    }
  }
  
  .rbc-event.calendar-event.hide-time-range.medium {
    .rbc-addons-dnd-resizable {
      .rbc-event-content {
        align-items: flex-start;
      }
    }
  }
  
  .rbc-event.calendar-event.block-off-time {
    .rbc-addons-dnd-resizable {
      .rbc-event-label {
        margin-bottom: 0px;
      }
    }
  }
  
  .rbc-event.calendar-event.crossed-title {
    .rbc-addons-dnd-resizable {
      .rbc-event-content {
        text-decoration: line-through;
      }
    }
  }
}

