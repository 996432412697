@import "src/assets/stylesheets/scss/tokens";

.requiredInputWrapper {
  margin-bottom: 1.5rem;
}

.bannerWrapper {
  margin: 1rem 0;
}

.formEditorContainer {
  height: 100%;
  overflow: auto;
}

// For use in Options folder

.optionWrapper {
  margin-bottom: 1rem;

  label {
    margin: 0 0 0.25rem 0 !important; // TextareaAdapter has some built in margin that we want to get rid of
  }
  // Need to adjust error-adapter positioning
  [data-error-adapter] {
    position: relative;
    margin-top: -0.5rem;
  }

  &.simpleValueAdapterMargin {
    // Simple Value Select Adapter adds a 0.75rem margin-bottom, so we want to only add another 0.25rem
    margin-bottom: 0.25rem;
  }
}

.checkboxWrapper {
  display: flex;
  div:first-child {
    margin-right: 1.5rem;
  }
}